import React, { useState } from "react";
import Layout from "../components/Layout";
import Meta from "../components/Meta";
import { motion } from "framer-motion";
import Recaptcha from "react-recaptcha";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  // Recaptcha code
  const verifyCallback = (res) => {
    if (res) {
      setIsVerified(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateValues();

    if (isVerified) {
      if (isValid) {
        setLoading(true);
        postForm(e);
      } else {
        alert("Please enter valid details");
      }
    } else {
      alert("Please verify ReCaptcha");
    }
  };

  const postForm = (e) => {
    var xhr = new XMLHttpRequest();
    //Url of google sheets script.
    xhr.open(
      "POST",
      "https://script.google.com/macros/s/AKfycbwG2s-JZOQ_w_wMPp1UQRyYmpIpUnSAgeMTzWOp5-j985ymEm7TnVRRNV92j_XWGAg1Vg/exec"
      // "https://script.google.com/macros/s/AKfycbx3qXLtCO6FPDMNuC43XOVcRZWR1YPx2Jo9rtbW1Y8NICNQGBPquPcPjYQ4Ja4haLoUyA/exec"
    );
    // "https://script.google.com/macros/s/AKfycbz84qulRbyyJJAn2fVcMd5wtQLQSEbjpa83kirfLYPQegojvG__o4NdlYS-i45ln0ZT/exec"
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        alert("Your Message has been submitted successfully.");
        setIsVerified(false);
        e.target.reset();
        window.grecaptcha.reset();
        setLoading(false);
        setFormData({
          Name: "",
          Email: "",
          Phone: "",
          Message: "",
        });
      }
    };
    xhr.onerror = (err) => {
      console.log(err);
      alert("An error occured");
      setLoading(false);
    };
    var encoded = Object.keys(formData)
      .map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(formData[k]);
      })
      .join("&");
    xhr.send(encoded);
  };

  const validateValues = () => {
    // Email validation (required and valid format)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (formData.Email.trim() === "") {
      alert("Email is required.");
      return false;
    } else if (!emailPattern.test(formData.Email)) {
      alert("Invalid email format.");
      return false;
    }
    if (formData.Message.trim() === "") {
      alert("Message is required.");
      return false;
    }
    if (formData.Name.trim() === "") {
      alert("Name is required.");
      return false;
    }
    return true;
  };

  return (
    <Layout>
      <Meta title="Contact Us - DBT" />
      <form onSubmit={handleSubmit} style={{ paddingTop: "80px" }}>
        <div className="container p-4">
          <div className="is-flex is-justify-content-center">
            <div className="box" style={{ maxWidth: "500px" }}>
              <div className="is-flex is-justify-content-center is-flex-direction-column">
                <p
                  className="text-heading mb-2 has-text-weight-bold"
                  style={{ fontSize: "25px" }}
                >
                  Getting in touch is easy!
                </p>
                <p className="mb-4">
                  We would love to discuss new projects, creative ideas, or opportunities
                </p>
              </div>

              <label htmlFor="Name" className="is-block mb-4">
                <span
                  className=" is-block mb-2 has-text-weight-semibold"
                  style={{ fontSize: "14px" }}
                >
                  What is your name? *
                </span>
                <input
                  required
                  name="Name"
                  type="text"
                  className="input"
                  value={formData.Name}
                  onChange={handleChange}
                />
              </label>

              <label htmlFor="Email" className="is-block mb-4">
                <span
                  className="is-block mb-2 has-text-weight-semibold"
                  style={{ fontSize: "14px" }}
                >
                  Your Email *
                </span>
                <input
                  required
                  name="Email"
                  type="email"
                  className="input"
                  value={formData.Email}
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="Phone" className="is-block mb-4">
                <span
                  className="is-block mb-2 has-text-weight-semibold"
                  style={{ fontSize: "14px" }}
                >
                  What is your Phone Number?{" "}
                </span>
                <input
                  name="Phone"
                  type="tel"
                  className="input"
                  value={formData.Phone}
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="Message" className="is-block mb-4">
                <span
                  className="is-block mb-2 has-text-weight-semibold"
                  style={{ fontSize: "14px" }}
                >
                  How can we help? *
                </span>
                <textarea
                  required
                  name="Message"
                  className="textarea"
                  rows="5"
                  value={formData.Message}
                  onChange={handleChange}
                ></textarea>
              </label>
              <div className="mb-4">
                <label
                  className="checkbox"
                  style={{
                    fontSize: "12px",
                    display: "block",

                  }}
                >
                  <input
                    type="checkbox"
                    style={{ marginRight: "8px" }}
                  />
                  By checking this box, I consent to receive text messages related to potential opportunities from Digital Biz Tech. You can reply STOP to opt-out at any time. For help, text (614)347-3250. Message and data rates may apply. Message frequency may vary. For more information, please refer to our {" "}
                  <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"  style={{ color: "blue", textDecoration: "underline" }}>
                    Privacy Policy & Terms
                  </a>.
                </label>
                <p style={{ fontSize: "14px", marginTop: "8px" }}>
                  
                </p>
              </div>


              <div className="mb-3">
                <Recaptcha
                  sitekey="6Lde__EbAAAAAOwSrPdof0BPi5Vh-E4adX4NA0yU"
                  render="explicit"
                  verifyCallback={verifyCallback}
                  expiredCallback={() => {
                    setIsVerified(false);
                  }}
                />
              </div>
              <div className="mb-4">
                <motion.button
                  className={`button px-4 ${loading ? "is-loading" : ""}`}
                  style={{ transitionDuration: "0s" }}
                  initial={{ border: "2px solid var(--secondary)", color: "#4a4a4a" }}
                  whileHover={{
                    backgroundColor: "var(--secondary)",
                    color: "#fff",
                  }}
                >
                  Contact Us
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
}
